

.tooltip {
  position: absolute;
  padding: 10px;    
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 1px 1px 10px rgba(128, 128, 128, 0.6);
  border: 0px;
  border-radius: 5px;
  pointer-events: none;
}

.counties {
  fill: none;
}

.states {
  fill: none;
  stroke: #fff;
}