
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
// Bootstrap mixins and functions
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/functions';

// Change variables here
@import 'volt/variables';

// Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

// Vendor
@import 'volt/vendor';

// volt mixins & functions
@import 'volt/mixins';
@import 'volt/functions';

// Utilities
@import 'volt/reboot';
@import 'volt/utilities';

// Layout
@import 'volt/layout';

// Components
@import 'volt/components';

// write your custom styles here!
@import 'custom';
