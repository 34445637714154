body.zignaAI_ui {
	background: #e7ecf0;
	font-size: 0.9rem;
	font-family: arial;
	overflow-x: hidden;
	iframe {
		&:last-child {
			display: none;
		}
	}
	.zignaAi_table_1 {
		th {
			font-weight: 700;
			padding: 12px 6px;
		}
		td {
			padding: 6px 6px;
		}
		tbody {
			tr {
				border: 5px solid #ffffff;
				td {
					background: linear-gradient(
						0deg,
						rgba(243, 243, 243, 1) 0%,
						#f3f3f3 100%
					);
					min-width: 200px;
					max-width: 200px;
					word-break: break-word;
					white-space: normal;
				}
			}
		}
	}
	.fw500 {
		font-weight: 500;
	}
	.fw400 {
		font-weight: 400;
	}
	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5,
	h6,
	.h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		color: $black;
	}
	table.table {
		color: $black;
		td {
			font-size: 14px;
			min-width: 200px;
			max-width: 200px;
			word-break: break-word;
			white-space: normal;
		}
		th {
			font-size: 14px;
			min-width: 200px;
			max-width: 200px;
			word-break: break-word;
			white-space: normal;
		}
	}
	.parentTable{
		padding: 1rem;
  		display: block;
  		
		.table, .zignaAi_table_1 {
			color: $black;
			.thead {
				overflow-y: auto;
				overflow-x: hidden;
			}
			.tbody {
				overflow-y: auto;
				overflow-x: hidden;
				.tr{
					
					.td{
						background: linear-gradient(
								0deg,
								rgba(243, 243, 243,1) 0%,
								#f3f3f3 100%
							);
					}
				}			
			}
			
			.th,
			.td {
			margin: 0;
			font-size: 14px;	
		
			position: relative;
			word-break: break-word;
			white-space: normal;
			}
		}	

		.zignaAi_newTable {
			color: $black;
			.thead {
				overflow-y: auto;
				overflow-x: hidden;
				
			}
			.tbody {
				overflow-y: auto;
				overflow-x: hidden;
				.tr{
					border-left:0px;
					border-right:0px;
				
					.td{
						
						background: linear-gradient(
								0deg,
								rgba(243, 243, 243, 1) 0%,
								#f3f3f3 100%
							);
					}
				}			
			}
			.th{
				background-color: #3d2260;
				color: white;
			}
			.th,
			.td {
			margin: 0;
			font-size: 14px;	
			padding: 0.5rem;
			border-left: 1px solid #3d2260;
        	// border-right: 1px solid #3d2260;
			position: relative;
			word-break: break-word;
			white-space: normal;
				&:last-of-type{
					border-right: 1px solid #3d2260;
				}
			}
			
		}
	}
	.numColor {
		color: $number_color;
	}
	.numLg {
		font-size: 2rem;
		font-weight: 600;
	}
	.card {
		border: 1px solid #dfdfdf;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
		border-radius: 5px;
		.card-header {
			padding: 1rem !important;
			border: none !important;
		}
		.card-body {
			padding: 1rem;
		}
	}
	.navbar-lg {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		height: 60px;
		.navbar-brand {
			img {
				height: 50px;
			}
		}
	}
	// .sidebar {
	// 	margin-top: 60px;
	// 	.simplebar-wrapper {
	// 		.simplebar-mask {
	// 			bottom: 60px;
	// 		}
	// 	}
	// }
	.customNavToggler {
		background-color: #262b40;
		border-color: #262b40;
		height: 50px;
		width: 50px;
		border-radius: 5px;
		position: fixed;
		top: 5px;
		left: 5px;
		z-index: 1111;
		padding: 0;
		label {
			width: 50px;
			height: 50px;
		}
		svg {
			transform: scale(1.5);
		}
		.svgMenu--2 {
			path {
				fill: none;
				stroke: #ffffff;
				stroke-width: 3;
				stroke-linecap: round;
				stroke-linejoin: round;
				--length: 24;
				--offset: -38;
				stroke-dasharray: var(--length) var(--total-length);
				stroke-dashoffset: var(--offset);
				transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
			}
			input {
				display: none;
			}
			.line--1,
			.line--3 {
				--total-length: 111.22813415527344;
				--offset: -50.22813415527344;
			}
			.line--2 {
				--total-length: 99;
			}
			input:checked + svg {
				path {
					transform: translateX(30px);
				}
				.line--1,
				.line--3 {
					--offset: -16.9705627485;
				}
				.line--2 {
					--offset: -20;
				}
			}
		}
		.cross input:checked + svg {
			.line--1,
			.line--3 {
				--length: 22.627416998;
			}
			.line--2 {
				--length: 0;
			}
		}
	}
	.zignaAISidebar {
		//max-width: 140px;
		margin-top: 60px;
		padding-top: 12px;
		background: #3d2260;
		.simplebar-wrapper {
			.simplebar-mask {
				bottom: 60px;
			}
		}
		.nav-item {
			max-width: 100%;
			padding: 0 10px;
			&.active {
				.nav-link {
					color: #eaedf2;
					background-color: #6750a4;
					// border: 0.0625rem solid #6750a4;
				}
			}

			.nav-link {
				font-size: 0.9rem;
				padding-top: 5px;
				padding-bottom: 5px;
				border: none;
				&:hover {
					color: #eaedf2;
					background-color: #6750a4;
					// border: 0.0625rem solid #6750a4;
				}
				// span {
				// 	display: flex;
				// 	flex-direction: column;
				// 	justify-content: center;
				// 	align-items: center;
				// 	text-align: center;
				// 	white-space: pre-line;
				// 	padding: 10px 0px;
				// 	padding-bottom: 0;
				// 	span {
				// 		// padding: 10px;
				// 	}
				// 	.sidebar-icon.svg-icon {
				// 		margin-right: 0;
				// 	}
				// }
			}
		}
	}
	.content {
		padding: 1rem;
	}

	@media (min-width: 1025px) {
		.content {
			// margin-left: 140px;
		}
		.collapse {
			&.sidebar {
				display: block;
			}
		}
		.customNavToggler {
			display: none;
		}
	}
	@media (max-width: 1024px) {
		.content {
			margin-left: 0;
		}
		.navbar-brand {
			padding-left: 50px !important;
		}
		.simplebar-mask {
			bottom: 20px !important;
		}
		.sidebar.collapse:not(.show) {
			display: block !important;
			left: -200px;
		}
		.sidebar.collapse {
			left: 0;
			transition: all 500ms;
			display: block;
		}
	}
	.d3Map path {
		cursor: pointer;
	}
	.d3Map .d3MapZoomIn span,
	.d3Map .d3MapZoomOut span {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 25px;
		height: 25px;
		font-weight: 700;
		font-size: 15px;
		cursor: pointer;
		// border: 1px solid #aaa;
	}
	.d3Map .d3MapZoomOut {
		border-top: 0;
		box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
		background-color: #fff;
	}
	.d3Map .d3MapZoomIn {
		border-bottom: 0;
		box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
		background-color: #fff;
	}
	#states {
		fill: #aaa;
	}

	.outline {
		fill: none;
		stroke: #000;
		stroke-width: 1.5px;
	}

	.feature {
		fill: #ccc;
	}

	#usCountyMap {
		path.mesh {
			fill: none;
			stroke: #fff;
			stroke-width: 0.5px;
			stroke-linejoin: round;
		}
		path {
			fill: #ccc;
			stroke: #fff;
			stroke-width: 0.5px;
		}
		.outline {
			fill: transparent;
			stroke: transparent;
		}
	}
	.county:hover {
		fill: orange;
	}
	.categoryWidget {
		.card-body {
			& > div {
				align-items: center;
				grid-gap: 0.5rem;
			}
		}
	}
	.legendSection {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		flex-direction: row;
		grid-gap: 1rem;
		justify-content: end;
		.legendRow {
			display: flex;
			grid-gap: 0.5rem;
			align-items: center;
		}
		.legendColor {
			width: 15px;
			height: 15px;
		}
		.legendValue {
			text-transform: capitalize;
		}
		.legendC1 {
			background: $legendC1;
		}
		.legendC2 {
			background: $legendC2;
		}
	}
	.form-control,
	.form-select,
	.btn {
		border-radius: 0.2rem;
		font-weight: 400;
	}
	.filterSection .form-control,
	.filterSection .form-select {
		min-width: 250px;
		background: #e9e5fb;
		border: 1px solid #b5aae5;
	}
	.priceWidget {
		height: 100%;
		.card-body {
			height: 100%;
			& > .col-sm-12 {
				height: 100%;
			}
		}
		.priceWidItem {
			border-bottom: 1px solid #dcdcdc;
			height: 33.3%;
			padding: 10px;
			&:last-child {
				border-bottom: none;
			}
		}
	}
	@media (max-width: 1024px) {
		.priceWidget {
			.card-body {
				& > div {
					display: flex;
				}
				.priceWidItem {
					border-bottom: none;
					border-right: 1px solid #dcdcdc;
					height: auto;
					padding: 10px;
					padding-left: 15px;
					width: 33.3%;
					&:last-child {
						border-right: none;
					}
				}
			}
		}
		.w-100-1024 {
			width: 100%;
		}
	}
	.filterSection .form-label {
		font-weight: 400;
		text-transform: capitalize;
	}

	.bg-boxPattern {
		position: absolute;
		height: 280px;
		width: 100%;
		background: #eef0f8;
		border-radius: 5px;
		background-image: repeating-linear-gradient(
				#d2d6e44d 5px,
				#d2d6e44d 6px,
				transparent 2px,
				transparent 15%
			),
			repeating-linear-gradient(
				to right,
				#ffffff08 0,
				#ffffff05 3px,
				transparent 3px,
				transparent 6%
			),
			repeating-linear-gradient(
				to right,
				#d2d6e44d 0,
				#d2d6e44d 0px,
				transparent 3px,
				transparent 3%
			),
			repeating-linear-gradient(
				#ffffff08 0,
				#ffffff12 1px,
				transparent 1px,
				transparent 10px
			);
	}
	.textGreen {
		color: #0d7318;
	}
	.textRed {
		color: #cf5353;
	}
	#priceDiffAvgWidgetChart {
		position: relative;
	}
	#priceDiffAvgWidgetChart
		> div:nth-child(2)
		> div
		> div
		> div
		> div
		> span:nth-child(1) {
		display: none !important;
	}
	.chartLegendCustom {
		position: absolute;
		border: 1px solid #ddd;
		border-radius: 5px;
	}
	.chartLegendCustom1 {
		top: 5px;
		left: 5px;
	}
	.chartLegendCustom2 {
		bottom: 75px;
		right: 5px;
	}
	.customLableBottomLine {
		display: flex;
		grid-gap: 12%;
		padding-left: 80px;
		width: calc(100% - 80px);
		justify-content: space-evenly;
	}
	.customLableBottomLine div:first-child {
		width: 100px;
	}
	.listType1 {
		max-width: 200px;
		border: 1px solid #d5d9ec;
		list-style: none;
		padding: 0;
		li {
			padding: 10px;
			font-weight: 500;
			line-height: 28px;
			&:first-child {
				background-color: #615e6b !important;
				color: #fff;
				align-items: flex-end;
				// height: 56px;
			}
			&:nth-child(odd) {
				background-color: #eaecf5;
			}
			&:nth-child(even) {
				background-color: #f2f4f9;
			}
		}
	}
	.m0-auto {
		margin: 0 auto;
	}
	.listType2 {
		max-width: 200px;
		// border: 1px solid #d5d9ec;
		list-style: none;
		padding: 0;
		li {
			padding: 10px;
			font-weight: 600;
			display: flex;
			grid-gap: 1rem;
			&:first-child {
				height: 56px;
				align-items: flex-end;
			}
			.li-diffValue {
				width: 50%;
			}
		}
	}
	.d-block-b768 {
		display: none;
	}
	@media (min-width: 768px) {
		.d-block-a768 {
			display: none;
		}
	}
	@media (max-width: 768px) {
		.w-100-b768 {
			width: 100%;
		}
		.d-block-b768 {
			display: block;
		}
		.d-none-b768 {
			display: none;
		}
		.mt-12-b768 {
			margin-top: 12px;
		}
		.pricCompSection svg > g > g:nth-child(1) {
			display: none;
		}
		// .pricCompSection svg:first-child {
		// 	margin-top: 10px;
		// }
		.d-block-a768 {
			display: block;
		}
	}
	.filterSection {
		.btn-group {
			border: 1px solid #b5aae5;
			border-radius: 5px;
			min-height: 44px;
			.btn {
				padding: 9px 10px;
				font-weight: 400;
				border: none;
				border-right: 1px solid #d9d9d9;
				cursor: pointer;
				&:last-child {
					border-right: none;
				}
				label {
					cursor: pointer;
				}
			}
		}
		.btn.submitFilter {
			padding: 8px 10px;
			font-weight: 400;
			margin-top: 10px;
		}
		.btn-check:checked + .btn,
		:not(.btn-check) + .btn:active,
		.btn:first-child:active,
		.btn.active,
		.btn.show,
		.btn:hover {
			background: #e9e5fb;
			color: #000;
		}
		.invalid-input {
			color: $danger;
			position: absolute;
		}
	}
	@media (max-width: 768px) {
		.priceWidget {
			height: 100%;
			.card-body {
				height: 100%;
				& > .col-sm-12 {
					height: 100%;
					flex-direction: column;
				}
			}
			.priceWidItem {
				border-bottom: 1px solid #dcdcdc !important;
				border-right: none !important;
				height: 33.3%;
				width: 100% !important;
				padding: 10px;
				&:last-child {
					border-bottom: none !important;
					border-right: none !important;
				}
			}
		}
		.dualList {
			display: flex;
			.listType1 {
				max-width: 100% !important;
				margin: 0;
				width: 50%;
			}
		}
	}
	.commonLabel {
		padding: 10px;
		background: #eaecf5;
		border-radius: 5px;
	}
	.form-control,
	.form-select,
	.btn {
		appearance: auto !important;
	}
	@media (max-width: 1400px) {
		.zignaAi_table_2 {
			thead {
				tr {
					&:last-child {
						th {
							&:nth-child(4) {
								border-right: 1px solid #ffffff;
							}
						}
					}
				}
			}
			&.sticky {
				overflow: scroll;
				.header,
				.footer {
					position: sticky;
					z-index: 1;
					width: fit-content;
				}

				.header {
					top: 0;
					box-shadow: 0px 3px 3px #ccc;
				}

				.footer {
					bottom: 0;
					box-shadow: 0px -3px 3px #ccc;
				}

				.body {
					position: relative;
					z-index: 0;
				}

				[data-sticky-td] {
					position: sticky;
				}

				[data-sticky-last-left-td] {
					box-shadow: 2px 0px 3px #ccc;
				}

				[data-sticky-first-right-td] {
					box-shadow: -2px 0px 3px #ccc;
				}
			}
			table-layout: auto !important;
			tr {
				th {
					&:first-child {
						position: sticky;
					}
				}
			}
		}
	}
	@media (min-width: 1400px) {
		.zignaAi_table_2 {
			tr {
				th {
					&:first-child {
						position: sticky;
					}
				}
				&:first-child {
					th {
						&:first-child {
							text-align: left;
							width: 15%;
						}
						&:nth-child(2) {
							text-align: left;
							width: 8%;
						}
						&:nth-child(n + 5) {
							&:nth-child(-n + 12) {
								width: 18%;
							}
						}
						&:nth-child(3) {
							width: 6%;
						}
						&:nth-child(4) {
							width: 8% !important;
						}
					}
				}
				th {
					background: #7f67be;
					color: #ffffff;
					white-space: pre-wrap;
					text-align: center;
				}
			}
		}
	}
	.zignaAi_table_2 {
		table-layout: fixed;
		th,
		td {
			overflow: hidden;
			white-space: pre-line;
			padding: 0.5rem 0.25rem;
		}
		thead {
			position: sticky;
			top: 0;
			z-index: 11;
		}
		tr {
			&:first-child {
				th {
					&:first-child {
						text-align: left;
					}
					&:nth-child(n + 5) {
						&:nth-child(-n + 12) {
							border-bottom: 1px solid #fff;
						}
					}
					&:nth-child(3) {
					}
					&:nth-child(4) {
						border-right: 1px solid #fff;
					}
				}
			}
			th {
				background: #7f67be;
				color: #ffffff;
				white-space: pre-wrap;
				text-align: center;
				font-weight: 500 !important;
			}
		}
		tbody {
			tr {
				border: 1px solid #ffffff;
				td {
					background: linear-gradient(
						0deg,
						rgba(237, 237, 237, 100%) 0%,
						#ffffff 100%
					);
					border-right: 1px solid #e4e4e4;
					text-align: center;
					padding: 5px;
					&:first-child {
						text-align: left;
						width: 15%;
					}
					&:last-child {
						border-right: none;
					}

					&:nth-child(n + 5) {
						&:nth-child(-n + 16) {
							// color: red;
						}
					}
				}
			}
		}
		tfoot {
			tr {
				td {
					background: linear-gradient(
						0deg,
						rgba(237, 237, 237, 100%) 0%,
						#ffffff 100%
					);
					padding: 0;
					border-right: 1px solid #e4e4e4;
					&:last-child {
						border-right: none;
					}
					&:first-child {
						div {
							& > div {
								display: flex;
								align-items: center;
								height: 50px;
								padding: 5px;
								border: 1px solid #ffffff;
								justify-content: start;
							}
						}
					}
					div {
						& > div {
							display: flex;
							align-items: center;
							height: 50px;
							padding: 5px;
							border: 1px solid #ffffff;
							justify-content: center;
						}
					}
				}
			}
		}
	}
	.table-responsive {
		&::-webkit-scrollbar {
			width: 10px;
			height: 10px;
			background-color: #f5f5f5;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			background-color: #8f8f8f;
		}
	}
	table {
		.tableCellBg_darkRed {
			background: #e35b5b;
		}
		.tableCellBg_lightRed {
			background: #ea8484;
		}
		.tableCellBg_yellow {
			background: #f5d151;
		}
		.tableCellBg_ligthGreen {
			background: #9fc886;
		}
		.tableCellBg_darkGreen {
			background: #67b039;
		}
		.tableCellCustom {
			display: inline-block;
			padding: 3px 5px;
			border-radius: 3px;
			min-width: 35px;
		}
	}
	.categoryWidget2 {
		background: #f4effc;
		padding: 10px;
		.catWidItem {
			position: relative;
			align-items: center;
			display: flex;
			flex-direction: column;
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				width: 2px;
				height: 60%;
				border: 1px solid #cabedd;
				transform: translate(0%, -50%);
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
		}
	}
	.numericVal {
		color: #3c265f;
		font-weight: 600;
	}
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 10px;
		background-color: #f5f5f5;
	}
	.ouro {
		position: relative;
		display: inline-block;
		height: 46px;
		width: 46px;
		margin: 1em;
		border-radius: 50%;
		background: none repeat scroll 0 0 #dddddd;
		overflow: hidden;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset,
			0 0 25px rgba(0, 0, 255, 0.075);
	}

	.ouro:after {
		content: '';
		position: absolute;
		top: 9px;
		left: 9px;
		display: block;
		height: 28px;
		width: 28px;
		background: none repeat scroll 0 0 #f2f2f2;
		border-radius: 50%;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	}
	.ouro > span {
		position: absolute;
		height: 100%;
		width: 50%;
		overflow: hidden;
	}
	.left {
		left: 0;
	}
	.right {
		left: 50%;
	}

	.anim {
		position: absolute;
		left: 100%;
		top: 0;
		height: 100%;
		width: 100%;
		border-radius: 999px;
		background: none repeat scroll 0 0 #3d2260;
		opacity: 0.8;
		-webkit-animation: ui-spinner-rotate-left 1.5s infinite;
		animation: ui-spinner-rotate-left 1.5s infinite;
		-webkit-transform-origin: 0 50% 0;
		transform-origin: 0 50% 0;
	}
	.left .anim {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
	.right .anim {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		left: -100%;
		-webkit-transform-origin: 100% 50% 0;
		transform-origin: 100% 50% 0;
	}

	/* v2 */
	.ouro2 .anim {
		-webkit-animation-delay: 0;
		animation-delay: 0;
	}
	.ouro2 .right .anim {
		-webkit-animation-delay: 500ms;
		animation-delay: 500ms;
	}

	/* v3 */
	.ouro3 .anim {
		-webkit-animation-delay: 0s;
		-webkit-animation-duration: 3s;
		-webkit-animation-timing-function: linear;
		animation-delay: 0s;
		animation-duration: 3s;
		animation-timing-function: linear;
	}
	.ouro3 .right .anim {
		-webkit-animation-name: ui-spinner-rotate-right;
		-webkit-animation-delay: 0;
		-webkit-animation-delay: 500ms;
		animation-name: ui-spinner-rotate-right;
		animation-delay: 0;
		animation-delay: 500ms;
	}

	/* round variation */
	.round .ouro:after {
		display: none;
	}

	/* double variation */
	.double .ouro:after {
		height: 13px;
		width: 13px;
		left: 7px;
		top: 7px;
		border: 10px solid #ddd;
		background: transparent;
		box-shadow: none;
	}

	@keyframes ui-spinner-rotate-right {
		0% {
			transform: rotate(0deg);
		}
		25% {
			transform: rotate(180deg);
		}
		50% {
			transform: rotate(180deg);
		}
		75% {
			transform: rotate(360deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes ui-spinner-rotate-left {
		0% {
			transform: rotate(0deg);
		}
		25% {
			transform: rotate(0deg);
		}
		50% {
			transform: rotate(180deg);
		}
		75% {
			transform: rotate(180deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	@-webkit-keyframes ui-spinner-rotate-right {
		0% {
			-webkit-transform: rotate(0deg);
		}
		25% {
			-webkit-transform: rotate(180deg);
		}
		50% {
			-webkit-transform: rotate(180deg);
		}
		75% {
			-webkit-transform: rotate(360deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes ui-spinner-rotate-left {
		0% {
			-webkit-transform: rotate(0deg);
		}
		25% {
			-webkit-transform: rotate(0deg);
		}
		50% {
			-webkit-transform: rotate(180deg);
		}
		75% {
			-webkit-transform: rotate(180deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	.downloadBtn {
		margin-right: 15px;
		float: right;
		font-size: 0.9rem;
		font-weight: 500;
		cursor: pointer;
		span {
			position: relative;
			top: 3px;
			margin-left: 5px;
			text-decoration: underline;
		}
		&:hover {
			opacity: 0.8;
		}
	}
	.fw700 {
		font-weight: 700;
	}
	.invalidFilter.select-menu {
		> div:nth-child(3) {
			outline: 1px solid #fa5252 !important;
			border: 0;
			box-shadow: 0 0 0 1px #fa5252 !important;
		}
	}
	.showLoading {
		* {
			filter: blur(1px);
		}
		.preloader {
			@include transition(height 0.1s);
			background-color: #f0edf5e0 !important;
			filter: blur(0px);
			height: 100vh;
			* {
				filter: blur(0px);
			}
			top: 0;
		}
	}
	.preloader {
		@include transition(height 0.1s);
		background-color: #f0edf5e0 !important;
		filter: blur(0px);
		height: 0;
		top: -100px;
	}
	.loadingText {
		position: relative;

		&:before {
			color: #aaa;
			content: attr(data-loading-text);
		}

		&:after {
			top: 0;
			left: 0;
			width: 0;
			opacity: 1;
			color: #5b4577;
			overflow: hidden;
			position: absolute;
			content: attr(data-loading-text);
			animation: loadingText 5s infinite;
		}

		@keyframes loadingText {
			0% {
				width: 0;
			}
			100% {
				width: 100%;
			}
		}
	}
	.blurOverlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #f0edf5e0 !important;
		// z-index: 11;
		filter: blur(0px) !important;
		padding: 2rem;
		text-align: center;
		text-transform: capitalize;
		h5 {
			text-transform: capitalize;
		}
	}
	.blurDiv {
		position: relative;
		div {
			filter: blur(1px);
		}
	}
	.vertical_separator {
		position: relative;
		&::after {
			content: '';
			background-color: #000;
			position: absolute;
			width: 5px;
			height: 100px;
			top: 10px;
			left: 50%;
			display: block;
		}
	}
	.sep_filter {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 60px;
	}
	.sepText {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 1;
	}
	// .sepText::before,
	.sepText::after {
		content: '';
		flex: 1;
		width: 1px;
		background: #bdbbc1;
		margin: 0.25em;
		position: relative;
		top: 10px;
	}
	.footer {
		position: fixed;
		height: 40px;
		bottom: 0;
		width: 100%;
		z-index: 111;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	.procedureCategory .nodataFilter {
		display: flex;
		height: 100%;
		width: 100%;
		position: absolute;
		align-items: center;
		justify-content: center;
		background: #e7e3f9;
		border-color: hsl(0, 0%, 80%);
	}
	.procedureCategory.invalidFilter {
		outline: 1px solid #fa5252 !important;
		border: 0;
		box-shadow: 0 0 0 1px #fa5252 !important;
	}
	.authPage {
		.invalid_input {
			border: 1px solid #fa5252;
			.input-group-text {
				border: 0;
			}
			.input-group {
				border: 0;
			}
			.is-invalid {
				border: 0;
			}
		}
		.input-group {
			border-radius: 0.3rem;
		}
	}
	&.login_page_bg {
		background-image: url(../assets/img/zigna_ai/login_bg.png);
		background-repeat: no-repeat;
		background-size: cover;
	}
	.w220 {
		width: 220px;
	}
	// Range Label
	.range-slider__value {
		display: inline-block;
		position: relative;
		width: 60px;
		height: 30px;
		color: #fff;
		line-height: 20px;
		text-align: center;
		border-radius: 3px;
		background: #262b40;
		padding: 5px 10px;
		margin-left: 8px;

		&:after {
			position: absolute;
			top: 8px;
			left: -7px;
			width: 0;
			height: 0;
			border-top: 7px solid transparent;
			border-right: 7px solid #262b40;
			border-bottom: 7px solid transparent;
			content: '';
		}
	}
	.h45 {
		height: 45px;
	}
	.NPI-select {
		.NPI-submit {
			height: 99%;
			align-items: center;
			display: flex;
			// background: #000;
			// color: #fff;
			// padding: 0.5rem;
			border-radius: 0 4px 4px 0 !important;
			// cursor: pointer;
		}
		.select-menu [class*='-control'] {
			border-radius: 4px 0 0 4px !important;
		}
	}
	.filterProcedureName {
		.select-menu [class*='-control'] {
			width: auto !important;
			min-width: 200px !important;
		}
	}
	.priceWidget {
		.card {
			background: #eef0f8;
			padding: 10px !important;
			.card-body {
				padding: 0 !important;
				h5 {
					font-size: 1rem !important;
				}
				.numLg {
					font-size: 1.5rem !important;
				}
			}
		}
	}
	.splashPage {
		.modal-dialog{
			@media (max-width: 1200px) {
			max-width: 80%;
			margin: 0 auto;
			}
		.modal-content {
			border: 1px solid #b4a8f0;
			border-top-color: $primary;
			border-width: 10px 1px 1px 1px;
			box-shadow: 0px 4px 4px #00000040;
			border-radius: 10px;
			.modal-body {
				padding: 2rem;
			}
			.icn_splash {
				position: absolute;
				top: -27px;
				left: 25px;
			}
			.splashContent {
				div {
					&:first-child {
						.card {
							@media (min-width: 1200px) {
								&::after {
									content: '';
									position: absolute;
									height: 100%;
									border: 1px solid #bebebe;
									right: -22%;
								}
							}
						}
					}
					.card {
						background: #e9e5fb;
						border-radius: 15px;
						box-shadow: none !important;
						position: relative;
						.card-body {
							padding: 1.5rem;
							padding-top: 3rem !important;
							min-height: 224px;
						}
					}
				}
			}
		}
		}
		
		.splashHeader {
			background: #e9e5fb;
			border: 1px dashed #aaa3db;
		}
	}
	.modal-backdrop {
		background: #f2f0f9;
		opacity: 0.9;
		filter: blur(0.2);
	}
	.widget_subheader {
		font-size: 1rem;
		text-transform: uppercase;
	}
	.br-1-grey {
		border-right: 1px solid #cccccc;
	}
}

.stickycss{
	position: sticky;
	top: 0;
}
.modal-open {
	#root {
		filter: blur(3px);
	}
}
