.pagination{
    --bs-pagination-padding-x: 1.75rem;
    --bs-pagination-padding-y: .875rem;
    --bs-pagination-font-size: 1rem;
    --bs-pagination-color: #3D2260;
    --bs-pagination-bg: #ffffff;
    --bs-pagination-border-width: 0.0625rem;
    --bs-pagination-border-color: #eaedf2;
    --bs-pagination-border-radius: 0.5rem;
    --bs-pagination-hover-color: #190e28;
    --bs-pagination-hover-bg: #f5f8fb;
    --bs-pagination-hover-border-color: #f0f3f6;
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: #f5f8fb;
    --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(61, 34, 96, 0.25);
    --bs-pagination-active-color: #ffffff;
    --bs-pagination-active-bg: #3D2260;
    --bs-pagination-active-border-color: #3D2260;
    --bs-pagination-disabled-color: #93a5be;
    --bs-pagination-disabled-bg: #ffffff;
    --bs-pagination-disabled-border-color: #f0f3f6;
    display: flex;
    padding-left: 0;
    list-style: none;
}